import { useEffect } from 'react';
import MainLayout from '@/layouts/MainLayout';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { PhoneIcon, InboxIcon } from '@heroicons/react/24/solid';
import OurMission from '@/components/OurMission';
import SecondaryCircle from '@/components/SecondaryCircle';
import Seo from '@/components/Seo';
import THDLBrandSVG from '@/icons/thdl-brand.inline.svg';
import OurMissionBG from '@/images/ourmission-bg.inline.svg';

type AboutPageType = PageProps<GatsbyTypes.AboutPageQuery>;

const AboutPage: React.FC<AboutPageType> = ({ location, data }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const { ourMissions } =
    (language === 'th' ? data.ourMissionsTH : data.ourMissionsEN)?.pages?.about
      ?.section_3?.components || {};

  useEffect(() => {
    const { id }: any = location.state;
    if (id === 'contact-us') {
      const ele = document.getElementById('contact-us');
      ele?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [location]);

  return (
    <MainLayout>
      <Seo title={t('seo.about.title')} description={t('seo.about.desc')} />

      {/* Section 1: TH DATA LAB INC. */}
      <section className="bg-primary-main">
        <div className="px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto space-y-5 text-center">
          <h1 className="font-semibold text-secondary-main text-4xl md:text-6xl">
            {t('pages.about.section-1.header-1')}
          </h1>
          <h2 className="text-neutral-50 text-2xl md:text-3xl">
            {t('pages.about.section-1.desc-1')}
          </h2>
        </div>
      </section>

      {/* Section 2: About Us */}
      <section className="flex flex-col lg:flex-row items-center lg:justify-between px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
        <div className="flex flex-col items-center space-y-10 lg:items-start lg:w-2/3">
          <h2 className="font-semibold text-neutral-900 text-4xl">
            {t('pages.about.section-2.header-1')}
          </h2>
          <THDLBrandSVG className="lg:hidden w-72 h-72" />
          <p className="text-neutral-900 text-lg">
            {t('pages.about.section-2.desc-1')}
          </p>
        </div>
        <THDLBrandSVG className="hidden lg:block w-72 h-72" />
      </section>

      {/* Section 3: Our Mission */}
      <section className="relative bg-primary-main overflow-hidden">
        <OurMissionBG className="hidden lg:block absolute -top-14 -right-48" />
        <OurMissionBG className="hidden lg:block absolute bottom-0 left-0" />
        <div className="flex flex-col items-center space-y-20 px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
          <div className="text-center space-y-6">
            <h2 className="font-semibold text-neutral-50 text-4xl">
              {t('pages.about.section-3.header-1')}
            </h2>
            <h3 className="text-neutral-50 text-2xl">
              {t('pages.about.section-3.desc-1')}
            </h3>
          </div>

          <SecondaryCircle
            title={t('pages.about.section-3.header-2')}
            className="lg:hidden"
          />

          <div className="flex flex-col lg:flex-row lg:flex-wrap lg:justify-between items-center justify-center space-y-20 lg:space-y-0">
            {ourMissions?.map((mission, key) => {
              const { title, desc, icon } = mission || {};
              return key === 2 ? (
                <React.Fragment key={key}>
                  <SecondaryCircle
                    title={t('pages.about.section-3.header-2')}
                    className="hidden lg:flex relative -top-16"
                  />
                  <OurMission
                    key={key}
                    title={title}
                    desc={desc}
                    icon={icon?.childImageSharp?.gatsbyImageData}
                    className="lg:items-end lg:relative lg:-top-24 text-center lg:text-right"
                  />
                </React.Fragment>
              ) : (
                <OurMission
                  key={key}
                  title={title}
                  desc={desc}
                  icon={icon?.childImageSharp?.gatsbyImageData}
                  className={`${
                    key % 2 === 0 ? 'lg:items-end' : 'lg:items-start'
                  } ${key === 3 && 'lg:relative lg:-top-24'}`}
                />
              );
            })}
          </div>
        </div>
      </section>

      {/* Section 4: Contact Us */}
      <div
        id="contact-us"
        className="flex flex-col lg:flex-row lg:flex-wrap lg:justify-between space-y-10 lg:space-y-20 lg:items-center px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto"
      >
        <h2 className="w-full font-semibold text-neutral-900 text-4xl text-left lg:text-center">
          {t('pages.about.section-4.header-1')}
        </h2>
        <div className="lg:w-1/2 lg:pr-6 flex flex-col space-y-6">
          <div className="flex flex-col text-left space-y-2">
            <p className="text-xl font-semibold text-primary-main">
              {t('pages.about.section-4.contact.working-hours.title')}
            </p>
            <span className="text-primary-main text-lg">
              {t('pages.about.section-4.contact.working-hours.desc')}
            </span>
          </div>
          <div className="flex flex-col text-left space-y-2">
            <p className="text-xl font-semibold text-primary-main">
              {t('pages.about.section-4.contact.address.title')}
            </p>
            <span className="text-primary-main text-lg">
              {t('pages.about.section-4.contact.address.desc')}
            </span>
          </div>
          <a
            className="flex items-center space-x-2 group"
            href={`tel:${t('pages.about.section-4.contact.phone')}`}
          >
            <PhoneIcon className="w-6 h-6 text-primary-main" />
            <span className="text-primary-main text-lg group-hover:text-secondary-hover">
              {t('pages.about.section-4.contact.phone')}
            </span>
          </a>
          <a
            className="flex items-center space-x-2 group"
            href={`mailto:${t('pages.about.section-4.contact.email')}`}
          >
            <InboxIcon className="w-6 h-6 text-primary-main" />
            <span className="text-primary-main text-lg group-hover:text-secondary-hover">
              {t('pages.about.section-4.contact.email')}
            </span>
          </a>
        </div>
        <div className="lg:w-1/2 lg:pl-6">
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.748859222239!2d100.51239781483024!3d13.733648990359175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2999cb642394d%3A0x30bc126296234b83!2sTONG%20HUA%20HOLDING%20PCL!5e0!3m2!1sen!2sth!4v1657095822723!5m2!1sen!2sth"
              className="w-full h-full border-0 rounded-2xl"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage($language: String!) {
    ourMissionsEN: enJson {
      pages {
        about {
          section_3 {
            components {
              ourMissions {
                title
                desc
                icon {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    ourMissionsTH: thJson {
      pages {
        about {
          section_3 {
            components {
              ourMissions {
                title
                desc
                icon {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "translation" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
