import React from 'react';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

type OurMissionType = {
  title?: string;
  desc?: string;
  icon?: GatsbyTypes.ImageSharp['gatsbyImageData'];
  className?: string;
};

const OurMission: React.FC<OurMissionType> = ({
  title,
  desc,
  icon,
  className,
}) => {
  return (
    <div
      className={classNames(
        `flex flex-col items-center justify-center space-y-4 lg:w-1/3`,
        className,
      )}
    >
      <div className="w-max h-max p-3 rounded-lg flex items-center justify-center bg-secondary-main bg-opacity-20">
        {icon && (
          <GatsbyImage alt={title || ''} image={icon} className="w-16 h-16" />
        )}
      </div>
      <p className="font-semibold text-2xl text-neutral-50">{title}</p>
      <span className="text-neutral-50 text-xl">{desc}</span>
    </div>
  );
};

export default OurMission;
