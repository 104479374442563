import classNames from 'classnames';
import React from 'react';

type SecondaryCircleType = {
  title: string;
  className?: string;
};

const SecondaryCircle: React.FC<SecondaryCircleType> = ({
  title,
  className,
}) => {
  return (
    <div className={classNames(`flex w-full justify-center py-10`, className)}>
      <div className="flex flex-col items-center justify-center text-center text-neutral-50 font-semibold text-5xl border border-secondary-main rounded-full w-80 h-80 whitespace-pre-line">
        {title}
      </div>
    </div>
  );
};

export default SecondaryCircle;
